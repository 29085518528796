<template>
    <CBadge :color="row.item.monthly_invoice ? 'success' : 'danger' ">
        {{ row.item.monthly_invoice ? $t('common.button.yes') : $t('common.button.no') }}
    </CBadge>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>